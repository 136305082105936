// src/App.js
import React from 'react';
import './App.css'; // Import your theme CSS/SCSS
import Widget from './components/Widget'; // Import your default widget component
import WidgetCard from './components/WidgetCard'; 

function App() {
  return (
    <div className="App">
      <h1>Welcome to My React App</h1>

      <WidgetCard title="Groceries" content="This card tracks Groceries." />
      <WidgetCard title="Entertainment" content="For all your entertainment needs." />

      {/* Add more widgets as needed */}
    </div>
  );
}

export default App;
