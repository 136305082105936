import React from "react";

function WidgetCard(props) {
    return (
        <div className="widget">
        {/* Widget content */}
        <h2>{props.title}</h2>
        <p>{props.content}</p>
        </div>
    );
    }

export default WidgetCard;
